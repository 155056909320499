import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const CHANNEL_ENTITY_LIST_FRAGMENT = `
  fragment channelEntityListFragment on Channel {
    id
    uid
    __typename
    schemaCode
    name
    description
    imageFileResource {
      ...fileResourceBaseFragment
    }
    thumbnailFileResource{
      ...fileResourceBaseFragment
    }
    _isFollowed(myUid: "%authUser%")
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
